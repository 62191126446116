import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import WertWidget from '@wert-io/widget-initializer';
import { signSmartContractData } from '@wert-io/widget-sc-signer';
import { v4 as uuidv4 } from 'uuid';
import Web3 from 'web3';
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { useAuth } from "../../../AuthContext";
import {
  useSetCheckUserMutation,
  useSetTempUserWalletMutation,
} from "../../../redux/user";
import {
  binanceChainEnum,
  binanceImage,
  buyCoinMethods,
  cardValue,
  chainConfigs,
  chainTypeEnum,
  charBtype,
  charEtype,
  coinTypeEnum,
  commonTokenPriceEnum,
  currencyTypeEnum,
  getAbiEnum,
  getFormattedBalanceToken,
  getLivePriceURL,
  getRpcByChain,
  minTRONUSDT,
  minUSDT,
  processTransaction,
  projectId,
  tokenAddressesEnum,
  ukcChainAbi,
  ukcChainAddress,
} from "../../constant/enum";
import { binanceChainId, bscRpcUrl, explorerUrlBsc } from "../../constant/bsc";
import {
  explorerUrlPolygon,
  polygonChainId,
  polygonRpcUrl,
} from "../../constant/poly";
import { ethChainId, EthRpcUrl, explorerUrlEth } from "../../constant/eth";
import {
  avalancheChainId,
  avalancheRpcUrl,
  explorerUrlAvalanche,
} from "../../constant/avalanche";
import RecentActivicity from "./RecentActivicity";
import { toast } from "react-toastify";
import {
  Contract,
  formatEther,
  JsonRpcProvider,
  BrowserProvider,
  parseEther,
  parseUnits,
} from "ethers";
import { useGetSignedDataMutation, useSetTransactionMutation } from "../../../redux/transactionApi";
import {
  useGetStageQuery,
  useGetTokenRaisedQuery,
} from "../../../redux/stageApi";
import { useGetgenWalletMutation } from "../../../redux/walletApi";
import GenWalletModal from "../../partial/GenWalletModal";
import SignupModal from "../../partial/SignupModal";
import NeedWalletModal from "../../partial/NeedWalletModal";
import { contractABI, contractAddress, origin, partnerId } from "../../constant/card";

function BuySell({referral_code}) {
  const { authenticated, login, logout, setWallet_address, wallet_address } =
    useAuth();
  const [getSignedData]=useGetSignedDataMutation()  
  const { walletProvider } = useWeb3ModalProvider();
  const [setCheckUser] = useSetCheckUserMutation();
  const [setTempUserWallet] = useSetTempUserWalletMutation();
  const [setTransaction] = useSetTransactionMutation();
  const [getgenWallet] = useGetgenWalletMutation();
  const { data: stage } = useGetStageQuery();
  const { data: tokenRaised } = useGetTokenRaisedQuery();
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const location = useLocation();
  const buyRef = useRef(null);
  const [chain, setChain] = useState(binanceChainEnum);
  const [currencyData, setCurrencyData] = useState([]);
  const [currency, setCurrency] = useState("");
  const [currencyBalance, setCurrencyBalance] = useState(0);
  const [coinPrice, setCoinPrice] = useState(1);
  const [latestPrice, setLatestPrice] = useState(1);
  const [ukcToken, setUkcToken] = useState(0);
  const [inputAmount, setInputAmount] = useState(0);
  const [wbtcPrice, setWbtcPrice] = useState(1);
  const [wethPrice, setWEthPrice] = useState(1);
  const [disable, setDisable] = useState(false);
  const [genWalletAddress, setGenWalletAddress] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [endtime, setEndtime] = useState("");
  const [customChain, setCustomChain] = useState("0");
  const [chainImage, setChainImage] = useState(binanceImage);
  const [showsignupModal, setShowsignupModal] = useState(false)
  const [showNeedModal, setShowNeedModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#buynow":
        scrollToRef(buyRef);
        break;
      default:
        break;
    }
  }, [location.hash]);

  // useEffect(() => {
  //   if (authenticated && wallet_address) {
  //     const checkConnection = () => {
  //       if (!isConnected) {
  //         logout();
  //         setCurrencyBalance(0);
  //         setCurrency("");
  //         localStorage.clear();
  //       } else if (address && address !== wallet_address) {
  //         logout();
  //         setCurrencyBalance(0);
  //         setCurrency("");
  //       }
  //     };
  //     const timeout = setTimeout(checkConnection, 4000);
  //     return () => clearTimeout(timeout);
  //   }
  // }, [address, isConnected, authenticated, wallet_address, logout]);

  useEffect(() => {
    if (address && isConnected) {
      const data = {
        wallet_address: address,
      };
      setDisable(true)
      setErrorMessage(false)
      setCheckUser(data).then((result) => {
        if (result.data && result.data.status) {
          login();
          localStorage.setItem("token", result.data.token);
          setWallet_address(address);
          localStorage.setItem("wallet_address", address);
          setTimeout(() => {
            setDisable(false)
          }, 4000);
          
        } else {
          setTempUserWallet(data);
          // setWallet_address(address);
          localStorage.setItem("wallet_address", address);
          setTimeout(() => {
            setDisable(false)
          }, 4000);
        }
      });
      if (chain && currency) {
        const chainType = chainTypeEnum.filter((list) => list.value == chain);
        if (chainType[0].custom != 1) {
          getUserBalance(chain, currency);
        }
      }
    }
  }, [address, isConnected]);

  const mainnet = [
    {
      chainId: binanceChainId,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: explorerUrlBsc,
      rpcUrl: bscRpcUrl,
    },
    {
      chainId: ethChainId,
      name: "Ethereum Mainnet",
      currency: "ETH",
      explorerUrl: explorerUrlEth,
      rpcUrl: EthRpcUrl,
    },
    {
      chainId: polygonChainId,
      name: "Polygon",
      currency: "MATIC",
      explorerUrl: explorerUrlPolygon,

      rpcUrl: polygonRpcUrl,
    },

    {
      chainId: avalancheChainId,
      name: "Avalanche",
      currency: "AVAX",
      explorerUrl: explorerUrlAvalanche,
      rpcUrl: avalancheRpcUrl,
    },
  ];

  const metadata = {
    name: "ukccoin",
    description: "ukc",
    url: "https://ukccoin.com/",
    icons: ["https://ukccoin.com/html/images/Logo.png"],
  };

  const openModal=createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  useEffect(() => {
    if (chain) {
      setCurrencyData(currencyTypeEnum[chain]);
      getAllPrice();
    }
  }, [chain]);

  const getAllPrice = async () => {
    try {
      let provider = new JsonRpcProvider(getRpcByChain[chain]);
      const contract = new Contract(
        ukcChainAddress[chain],
        ukcChainAbi[chain],
        provider
      );
      const result = await contract.allPrice();
      let coin_price = Number(result[0]);

      let tokenPrice = Number(result[1]);
      let tokenPriceDecimalVal = Number(result[2]);
      let tokenPriceDecimal = Math.pow(10, tokenPriceDecimalVal);
      let price = tokenPrice / tokenPriceDecimal;
      let latest_price = Number(price)
        .toFixed(tokenPriceDecimalVal)
        .replace(/\.?0+$/, "");

      let wbtcTokenPrice = Number(result[3]);
      let wbtcTokenPriceDecimalVal = Number(result[4]);
      let wbtcTokenPriceDecimal = Math.pow(10, wbtcTokenPriceDecimalVal);
      let wbtcPrice = wbtcTokenPrice / wbtcTokenPriceDecimal;

      let wbtcPriceLatest = Number(wbtcPrice)
        .toFixed(wbtcTokenPriceDecimalVal)
        .replace(/\.?0+$/, "");

      let wethTokenPrice = Number(result[5]);
      let wethTokenPriceDecimalVal = Number(result[6]);
      let wethTokenPriceDecimal = Math.pow(10, wethTokenPriceDecimalVal);
      var wethPrice = wethTokenPrice / wethTokenPriceDecimal;
      let wethPriceLatest = Number(wethPrice)
        .toFixed(wethTokenPriceDecimalVal)
        .replace(/\.?0+$/, "");
      setWbtcPrice(wbtcPriceLatest);
      setWEthPrice(wethPriceLatest);
      setCoinPrice(coin_price);
      setLatestPrice(latest_price);
    } catch (error) {}
  };

  useEffect(() => {
    calculateToken(inputAmount);
  }, [inputAmount]);

  const calculateToken = async (value) => {
    if (value && value != ".") {
      if (currency) {
        const currencyType = currencyData.filter(
          (list) => list.value == currency
        );
        const chainType = chainTypeEnum.filter((list) => list.value == chain);
        if (chainType[0].custom == 1) {
          if (currencyType[0].type == coinTypeEnum) {
            const ukcOneDollarPrice = 1 / stage[0].ukc_token_price;
            const getData = await axios.get(
              `${getLivePriceURL}${currencyType[0].key}USDT`
            );
            const getukcToken =
              ukcOneDollarPrice *
              parseFloat(value) *
              parseFloat(getData.data.price);
            setUkcToken(parseFloat(getukcToken).toFixed(2));
          } else {
            const getukcToken = parseFloat(value) / stage[0].ukc_token_price;
            setUkcToken(parseFloat(getukcToken).toFixed(2));
          }
        }
        else if(chainType[0].custom == 2){
          const getukcToken = parseFloat(value) / stage[0].ukc_token_price;
          setUkcToken(parseFloat(getukcToken).toFixed(2));
        }
         else {
          let res;
          if (currencyType[0].type == coinTypeEnum) {
            res = (parseFloat(value) * coinPrice) / (latestPrice * 100000000);
          } else if (currencyType[0]?.char == charBtype) {
            res = (parseFloat(value) * wbtcPrice) / latestPrice;
          } else if (currencyType[0]?.char == charEtype) {
            res = (parseFloat(value) * wethPrice) / latestPrice;
          } else {
            res = (parseFloat(value) * commonTokenPriceEnum) / latestPrice;
          }
          res = res.toFixed(2);
          setUkcToken(res);
        }
      } else {
        toast.dismiss();
        toast.error("Please select currency");
      }
    } else {
      setUkcToken(0);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    
    if (name === "chain") {
      setChain(value);
      setCurrency("");
      setCurrencyBalance(0);
      setInputAmount(0);
      setUkcToken(0);
      const chainType = chainTypeEnum.filter((list) => list.value == value);
      setCustomChain(chainType[0].custom);
      setChainImage(chainType[0].image);
      if(!address && value==cardValue){
        setErrorMessage(true)
        return
      }
      else{
        setErrorMessage(false)
      }
      if(!address){
        toast.dismiss()
        toast.error("Please connect wallet")
        return
      }
      if(chainType[0].custom==0){
        await switchToChain(value);
      }
      else if(chainType[0].custom==2){
        setCurrency("0");
        setInputAmount(0);
        setUkcToken(0);
      }
    }
    if (name === "currency") {
      setCurrency(value);
      setInputAmount(0);
      setUkcToken(0);
      const chainType = chainTypeEnum.filter((list) => list.value == chain);
      if (chainType[0].custom != 1 && chainType[0].custom != 2) {
        if (isConnected && value) {
          getUserBalance(chain, value);
        } else {
          setCurrencyBalance(0);
        }
      }
    }
    if (name === "inputAmount") {
      setInputAmount(value);
    }
  };

  const getUserBalance = async (chain, currency) => {
    const currencyType = currencyData.filter((list) => list.value == currency);
    const rpcUrls = [bscRpcUrl, EthRpcUrl, polygonRpcUrl, avalancheRpcUrl];
    const provider = new JsonRpcProvider(rpcUrls[chain]);
    if (currencyType[0].type == coinTypeEnum) {
      let balance = await provider.getBalance(address);
      setCurrencyBalance(formatEther(balance));
      return formatEther(balance);
    } else {
      const getTokenAddr = tokenAddressesEnum[chain][currency] || "";

      const getAbi = await getAbiEnum(chain, currency);
      const contract = new Contract(getTokenAddr, getAbi, provider);
      let balance = await contract.balanceOf(address);
      const formatBalance = await getFormattedBalanceToken(
        balance,
        chain,
        currency
      );
      setCurrencyBalance(formatBalance);
      return formatBalance;
    }
  };

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      throw error;
    }
  };

  const switchChain = async (
    chain,
    chainName,
    rpcUrl,
    explorer,
    native,
    symbol
  ) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chain,
                chainName: chainName,
                rpcUrls: [rpcUrl],
                blockExplorerUrls: [explorer],
                nativeCurrency: {
                  name: native,
                  symbol: symbol,
                  decimals: 18,
                },
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
        }
      } else {
        console.error(switchError);
      }
    }
  };

  const switchToChain = async (selectedChain) => {
    const chain = chainConfigs.find((chain) => chain.id == selectedChain);
    if (!chain) {
      toast.error("Invalid chain selected");
      setDisable(false);
      return false;
    }

    await switchChain(
      chain.network,
      chain.chainName,
      chain.rpcUrl,
      chain.explorerUrl,
      chain.nativeCurrency,
      chain.symbol
    );

    if (chainId != chain.chainId) {
      toast.error(`Please change network to ${chain.name}`);
      setDisable(false);
      return false;
    }
    return true;
  };

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  const approveToken = async (
    tokenAddress,
    provider,
    spender,
    decimals,
    chainabi
  ) => {
    try {
      const contract = new Contract(tokenAddress, chainabi, provider);
      const allowance = await contract.allowance(address, spender);
      const requiredAllowance = Number(inputAmount) * Number(10 ** decimals);
      if (Number(allowance) < requiredAllowance) {
        const approvalTx = await contract.approve(
          spender,
          "10000000000000000000000000000000000000000000000000000"
        );
        await approvalTx.wait();
        toast.dismiss();
        toast.success("Approved successfully. Please don't refresh the page.");
        setDisable(false);
        return false;
      } else {
        return true;
      }
    } catch (error) {
      toast.error("Approval failed. Please try again.");
      setDisable(false);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!address) {
      toast.dismiss();
      toast.error("Please connect your wallet");
      return;
    }
    if (!authenticated) {
      // window.location.href = "/#signup";
      setShowsignupModal(true)
      return;
    }

    if (currency == "") {
      toast.dismiss();
      toast.error("Please select currency");
      return;
    }
    if (inputAmount == "" || inputAmount <= 0) {
      toast.dismiss();
      toast.error("Please enter valid amount");
      return;
    }
    const currencyType = currencyData.filter((list) => list.value == currency);
    const chainType = chainTypeEnum.filter((list) => list.value == chain);
    let send_usdt_amount = 0;
    if (currencyType[0].priceCoin == "USDT") {
      if (currencyType[0].minPrice == "TRON") {
        if (inputAmount < minTRONUSDT) {
          toast.dismiss();
          toast.error(`Please enter minimum ${minTRONUSDT} USDT`);
          return;
        }
      } else {
        if (inputAmount < minUSDT) {
          toast.dismiss();
          toast.error(`Please enter minimum ${minUSDT} USDT`);
          return;
        }
      }
      send_usdt_amount = inputAmount;
    } else {
      const getData = await axios.get(
        `${getLivePriceURL}${currencyType[0].priceCoin}USDT`
      );
      let usdtAmount;
      if (currencyType[0].minPrice == "TRON") {
        usdtAmount = minTRONUSDT / parseFloat(getData.data.price);
      } else {
        usdtAmount = minUSDT / parseFloat(getData.data.price);
      }
      if (parseFloat(inputAmount) < parseFloat(usdtAmount).toFixed(8)) {
        toast.dismiss();
        toast.error(
          `Please enter minimum ${parseFloat(usdtAmount).toFixed(8)} ${
            currencyType[0].key
          }`
        );
        return;
      }
      send_usdt_amount = inputAmount * parseFloat(getData.data.price);
    }

    if (chainType[0].custom == 1) {
      const data = {
        input_amount: inputAmount,
        ukc_token: ukcToken,
        chain: chain,
        currency: currency,
        send_usdt_amount: send_usdt_amount,
      };
      let result = await getgenWallet(data);
      setGenWalletAddress(result.data.wallet_address);
      setEndtime(result.data.end_time);
      setShowModal(true);
    } 
    else if(chainType[0].custom == 2){
      const getData = await axios.get(
        `${getLivePriceURL}POLUSDT`
      );
      const price=getData.data.price
      handleWidgetOpen(inputAmount/price)
    }
    else {
      const userBalance = await getUserBalance(chain, currency);
      if (parseFloat(userBalance).toFixed(8) < parseFloat(inputAmount)) {
        toast.dismiss();
        toast.error("Insufficient balance");
        return;
      }
      setDisable(true);

      try {
        if (currencyType[0].type == coinTypeEnum) {
          const provider = await getSignerOrProvider();
          const resultTest = await switchToChain(chain);
          if (resultTest) {
            window.addEventListener("beforeunload", handleBeforeUnload);
            const contract = new Contract(
              ukcChainAddress[chain],
              ukcChainAbi[chain],
              provider
            );
            const valueInWei = parseEther(inputAmount.toString());
            const getMethod = await buyCoinMethods(contract, chain);
            const balance = await getMethod.method(getMethod.address, {
              value: valueInWei,
            });
            const transactionHash = balance.hash;
            const data = {
              user_address: address,
              chain: chainType[0].key,
              currency: currencyType[0].key,
              amount: inputAmount,
              ukc_token: ukcToken,
              trans_id: transactionHash,
              send_usdt_amount: send_usdt_amount,
              to_address: getMethod.address,
            };
            setTimeout(() => {
              setDisable(false);
              setUkcToken(0);
              setInputAmount(0);
              getUserBalance(chain, currency);
              setTransaction(data).then((result) => {
                if (result.data.status) {
                  toast.dismiss();
                  toast.success(result.data.message);
                }
              });
            }, 10000);
          } else {
          }
        } else {
          const provider = await getSignerOrProvider();
          const input_send_amount = parseUnits(inputAmount, 18);
          const getData = await processTransaction(
            chain,
            chainId,
            currency,
            inputAmount
          );
          if (!getData?.status) {
            await switchToChain(chain);
            toast.dismiss();
            toast.error(getData?.message);
            return;
          }
          window.addEventListener("beforeunload", handleBeforeUnload);
          const result = await approveToken(
            getData?.getTokenAddr,
            provider,
            ukcChainAddress[chain],
            currencyType[0].decimal,
            getData?.chainabi
          );
          if (result) {
            let balance;
            let contract = new Contract(
              ukcChainAddress[chain],
              ukcChainAbi[chain],
              provider
            );
            if (chain == 0) {
              balance = await contract.buyWithToken(
                input_send_amount,
                currency
              );
            } else {
              balance = await contract.buyWithToken(
                getData?.updatetAmt,
                currency
              );
            }
            const transactionHash = balance.hash;
            const data = {
              user_address: address,
              chain: chainType[0].key,
              currency: currencyType[0].key,
              amount: inputAmount,
              ukc_token: ukcToken,
              trans_id: transactionHash,
              send_usdt_amount: send_usdt_amount,
            };
            setTimeout(() => {
              setDisable(false);
              setUkcToken(0);
              setInputAmount(0);
              getUserBalance(chain, currency);
              setTransaction(data).then((result) => {
                if (result.data.status) {
                  toast.dismiss();
                  toast.success(result.data.message);
                }
              });
            }, 10000);
          }
        }
      } catch (error) {
        console.log(error.action, "error");
        toast.error(error.action);
        setDisable(false);
        return false;
      }
    }
  };

  const handleMax = (value) => {
    setInputAmount(value);
  };

  const handleWidgetOpen = async (amount) => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        const sc_input_data = contract.methods.MintNft(address, 1).encodeABI();
        const data={
          address:address,
          sc_input_data:sc_input_data,
          amount:parseFloat(amount).toFixed(8)
        }
        setDisable(true)
        const result =await getSignedData(data)
        // const signedData = signSmartContractData(
        //   {
        //     address: address,
        //     commodity: 'POL',
        //     commodity_amount: parseFloat(amount).toFixed(2),
        //     network: 'amoy',
        //     sc_address: contractAddress,
        //     sc_input_data,
        //   },
        //   privateKey
        // );
        if(result.data.status){
          const nftOptions = {
            item_info: {
              author: 'UKCCOIN',
              image_url: 'https://ukccoin.io/assets/img/l_coin.png',
              name: 'UKCCOIN',
              seller: 'UKCCOIN',
              header: 'UKCCOIN header',
            },
          };
          const otherWidgetOptions = {
            partner_id: partnerId,
            click_id: uuidv4(),
            origin: origin,
            extra: nftOptions,
            theme: "dark",
            is_crypto_hidden: true
          };
          const wertWidget = new WertWidget({
            ...result.data.data,
            ...otherWidgetOptions,
          });
          setTimeout(() => {
            setDisable(false)
          }, 3000);
          wertWidget.open();
        }
        else{
          toast.dismiss()
          toast.error(result?.data.message)
        }
      } catch (error) {
        setDisable(false)
        toast.dismiss()
        toast.error(error)
      }
    } else {
      setDisable(false)
      toast.dismiss()
        toast.error("Please connect wallet")
    }
  };

  return (
    <>
      <section
        className="buy_sell_section position-relative"
        id="buysell"
        ref={buyRef}
      >
        <div className="container mb-4">
          <img
            src="/assets/img/ub.png"
            alt="cricket"
            className="w100"
            data-aos="fade-up"
          />
        </div>

        <div className="position-relative   pt-4 pb-4">
          <div className="container-fluid ">
            <div className="row align-items-center">
            <div className="col-md-6 order-md-2" data-aos="fade-left">
                <div className="buy_sell_box  position-relative">
                {disable &&  <div className="buy_sell_box_ab  position-absolute">
                  <span class="loader"></span>

                   </div>}
                  <div className="b_box mb-4 fw-medium">
                    <div className="d-flex  align-items-center mb-4">
                      <h4 className="fmenda mb-0 me-auto color2">
                        Stage {stage?.length > 0 && stage[0]?.stage}
                      </h4>{" "}
                      <w3m-button balance="hide" />
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <div className="d-flex align-items-center me-auto">
                        <span>PRESALE IS LIVE</span>
                        <span className="round_b"></span>
                      </div>
                      <span className="color2 me-2">USDT RAISED: </span>

                      <span className="round_b">
                        $
                        {tokenRaised?.length > 0 && tokenRaised[0]?.usdt_raised}
                      </span>

                      {/* <span className="color2 ms-2 me-2">TOKEN SOLD: </span>

                      <span className="round_b">
                        ${tokenRaised?.length > 0 && tokenRaised[0]?.sold_ukc}
                      </span> */}
                    </div>

                    <div className="range-slider single-thumb ">
                      {/* <input
                        type="range"
                        className="myinput"
                        // min="1000"
                        max={stage?.length > 0 && stage[0]?.token_for_sale}
                        value={
                          stage?.length > 0 &&
                          stage[0]?.token_for_sale -
                            stage[0]?.left_token_for_sale
                        }
                      /> */}

                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{
                            width:`${stage && (parseFloat(
                              ((stage[0]?.token_for_sale -
                                stage[0]?.left_token_for_sale) /
                                stage[0]?.token_for_sale) *
                                100
                            ).toFixed(2))}%`,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {/* {stage && (parseFloat(
                            ((stage[0]?.token_for_sale -
                              stage[0]?.left_token_for_sale) /
                              stage[0]?.token_for_sale) *
                              100
                          ).toFixed(2))}
                          % */}
                        </div>
                      </div>
                    </div>

                    <h3 className="text-center mt-3 mb-0 fmenda">
                      1 UKC = ${stage?.length > 0 && stage[0]?.ukc_token_price}
                    </h3>
                  </div>
                  <div className="b_box mb-4 h5 fw-semibold">
                    <div className="d-flex align-items-center ">
                      <div className="d-flex align-items-center me-auto">
                        <span className="color2 me-3">
                          LISTING
                          <br />
                          PRICE
                        </span>
                        <span className="">
                          TBA
                          <br />
                          <br />
                        </span>
                      </div>
                      <div className="d-flex align-items-center me-md-auto">
                        <span className="color2 me-3">
                          NEXT
                          <br />
                          PRICE
                        </span>
                        <span className="">
                          $
                          {stage?.length > 0 &&
                            (stage[0].stage == 21
                              ? stage[0]?.ukc_token_price
                              : stage[1]?.ukc_token_price)}
                          <br />
                          (+50%)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <h5 className="fw700 mb-2 h55">Select chain</h5>
                      <div className="b_box b_box_p d-flex select_box position-relative  align-items-center">
                        <img src={chainImage} className="card_img " />
                        <h5 className="mb-0 ms-3 fw700 me-auto">Chain</h5>
                        <select
                          className="select_r"
                          onChange={handleChange}
                          value={chain}
                          name="chain"
                        >
                          {chainTypeEnum?.map((list) => {
                            return (
                              <option value={list.value} key={list.key}>
                                {list.key}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <h5 className="fw700 mb-2 h55">Select currency</h5>
                      <div className="b_box b_box_p d-flex select_box position-relative  align-items-center cur-height">
                        <select
                          className="select_r"
                          onChange={handleChange}
                          value={currency}
                          name="currency"
                        >
                          <option value="">Select Currency</option>
                          {currencyData?.map((list) => {
                            return (
                              <option value={list.value} key={list.key}>
                                {list.key}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {errorMessage && <div className="col-md-12 mb-3">
                      <b className="text-danger">To Buy UKC COIN with Card, connect your wallet and use the same wallet to claim your tokens on launch.</b>
                      </div>}
                    <div className="col-md-12 mb-3">
                      <h5 className="fw700 mb-2 d-flex h55 align-items-center">
                        {chain==cardValue? "USD Card" : "Balance"}
                        {customChain != 1 &&customChain != 2 ? (
                          <>
                            <span className="ms-auto">
                              {parseFloat(currencyBalance).toFixed(6)}
                            </span>{" "}
                            {parseFloat(currencyBalance) > 0 ? (
                              <button
                                className="btn btn-primary ms-2"
                                onClick={() => {
                                  handleMax(
                                    parseFloat(currencyBalance).toFixed(8)
                                  );
                                }}
                              >
                                Max
                              </button>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </h5>
                      <div className="b_box d-flex select_box position-relative b_box_p   align-items-center">
                        <img src={chainImage} className="card_img " />
                        <input
                          className="input_r"
                          placeholder="0"
                          name="inputAmount"
                          onChange={handleChange}
                          value={inputAmount}
                          onKeyPress={(event) => {
                            const inputValue = event.target.value;

                            // Allow only numbers and one decimal point
                            if (!/[0-9]/.test(event.key) && event.key !== ".") {
                              event.preventDefault();
                            }

                            // Prevent entering more than one decimal point
                            if (event.key === "." && inputValue.includes(".")) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <h5 className="fw700 mb-2 d-flex h55">
                        UKCCOIN
                        <span className="ms-auto "> {ukcToken}</span>
                      </h5>
                      <div className="b_box d-flex select_box position-relative b_box_p pt-3 pb-3  align-items-center">
                        <h5 className="mb-0 fw700 me-auto img-logo"><img src="/assets/img/l_coin.png"/></h5>
                        <input
                          className="input_r"
                          placeholder="0"
                          value={ukcToken}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  {isConnected ? (
                    <button
                      className="btn btn_login w100 fw600 d-flex"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={disable}
                    >
                      <span className="d-flex align-items-center m-auto">
                        Approve & Buy
                      </span>
                    </button>
                  ) : (
                    ""
                    // <w3m-button balance="hide" />
                  )}

                  {authenticated ? (
                    <Link
                      className="btn btn_login w100 fw600 d-flex mt-3"
                      to="/transaction"
                    >
                      <span className="d-flex align-items-center m-auto">
                        Dashboard
                      </span>
                    </Link>
                  ) : (
                    <div className="text_btns mt-3 text-center fw600">
                      <a href="/#signup">Signup</a>
                      <a className="bra" onClick={()=>{openModal.open()}}>Connect wallet</a><br/>
                      <span onClick={()=>{setShowNeedModal(true)}}>Don't have a wallet?</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 order-md-1" data-aos="fade-up">
                <RecentActivicity />
              </div>
             
            </div>
          </div>

          {/* <div className=" comming_soon text-center d-flex"> 
         <div className="m-auto"> 
         <h2 className="fmenda pt-md-4"> UKCCOIN PRESALE</h2>
         <h3 className="fmenda"> COMING SOON</h3>
        </div> 
        </div>  */}
        </div>
        <div className="container-fluid ">
          <div className="row mt-5 saping_sec">
            <div className="col-md-5 m-auto" data-aos="fade-up-right">
              <img
                src="/assets/img/i3.png"
                alt="i3"
                className="img-fluid h100"
              />
              <p className="mt-4">
                UKCCOIN isn’t just about watching cricket; it’s about actively
                shaping it. By empowering fans, bridging divides, and offering
                true digital ownership, UKCCOIN is setting the stage for a
                future where cricket is driven by those who love it most.
              </p>
            </div>
            <div className="col-md-6 ms-auto">
              <h1 className="fmenda color2 mb-0" data-aos="zoom-out-up">
                Shaping the Future of Cricket
              </h1>
            </div>
          </div>
        </div>
        <GenWalletModal
          setShowModal={setShowModal}
          genWalletAddress={genWalletAddress}
          showModal={showModal}
          inputAmount={inputAmount}
          ukcToken={ukcToken}
          endtime={endtime}
        />
         <SignupModal setShowsignupModal={setShowsignupModal} showsignupModal={showsignupModal} referral_code={referral_code}/>
      </section>
      <NeedWalletModal setShowNeedModal={setShowNeedModal} showNeedModal={showNeedModal}/>
    </>
  );
}

export default BuySell;
